<template>
  <div class="ui-overflow">
    <slot></slot>
  </div>
</template>

<script>
import { UiItem, UiIcon } from '@/modules/ui/components';

export default {
  name: 'ui-overflow',
  components: { UiItem, UiIcon },

  data() {
    return {
      resizeObserver: null,
      mutationObserver: null,
    };
  },

  mounted() {
    let targetElement =
      this.$el.childNodes.length == 1 ? this.$el.firstChild : this.$el;

    this.resizeObserver = new ResizeObserver(this.updateHiddenCount);
    this.resizeObserver.observe(targetElement);

    this.mutationObserver = new MutationObserver(this.updateHiddenCount);
    this.mutationObserver.observe(targetElement, {
      childList: true,
      attributes: false,
      subtree: false,
    });
  },

  destroyed() {
    this.resizeObserver.disconnect();
    this.mutationObserver.disconnect();
  },

  methods: {
    updateHiddenCount() {
      let count = 0;
      let elBound = this.$el.getBoundingClientRect();

      let targetNodes =
        this.$el.childNodes.length == 1
          ? this.$el.firstChild.childNodes
          : this.$el.childNodes;

      targetNodes.forEach((child) => {
        let childBound = child.getBoundingClientRect();
        if (childBound.right + this.$el.scrollLeft > elBound.right) {
          count++;
        }
      });

      this.$emit('update:hiddenCount', count);
    },
  },
};
</script>

<style lang="scss">
.ui-overflow {
  overflow: hidden;
}
</style>